$prefix: 'input-select';
$height: 32px;

.#{$prefix} {

  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: rgba($body-text-color, 0.8);

  .#{$prefix}__control {
    color: rgba($body-text-color, 0.8);

    min-height: $height;

    background-color: $input-bg;
    border-color: $input-border-color;
    background-clip: padding-box;
    border-radius: 0.25rem;

    &:hover {
      border-color: $input-border-color;
    }
  }

  .#{$prefix}__control--is-focused {
    box-shadow: none;
    border-color: $input-focus-border-color;
  }

  .#{$prefix}__control--is-open {

  }

  .#{$prefix}__menu {
  }

  .#{$prefix}__value-container {
    padding-left: 20px;
    padding-top: 0;
    padding-bottom: 0;
    letter-spacing: normal;
    min-height: $height;
  }

  .#{$prefix}__indicators {
    min-height: $height;

    .#{$prefix}__separator {
      //min-height: $height;
    }

    .#{$prefix}__indicator {
      //min-height: $height;
      padding: 6px;
    }
  }
}

.#{$prefix}.is-invalid {

  .#{$prefix}__control {
    color: $input-invalid-color;
    border-color: $input-invalid-bg;

    padding-right: calc(1.5em + .75rem);
    background: $input-invalid-bg url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E") no-repeat right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    transition: none;

    &:hover {
      border-color: $input-invalid-bg;
    }
  }
}