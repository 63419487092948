/* Authentication Pages */

.authentication-theme {
  .signup-link {
    margin: 10px auto 0px auto;
    //text-align: center;
    //display: flex;
    //justify-content: center;

    a {
      display: inline-block;
      color: #365963;

      &:hover {
        color: #FF4F5A;
      }
    }
  }

  .auth_footer {
    margin-top: 30px;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    font-size: 12px;
  }

  &.auth-style_1 {
    height: 100vh;
    width: 100%;
    background: #fff;
    padding: 10% 0 2px 0;

    overflow-x: hidden;

    .logo-section {
      //margin-bottom: 50px;
    }

    .grid {
      background: transparent;
      box-shadow: none;
      border: none;

      .grid-body {
        padding: 20px 15px 60px 15px;
        position: relative;

        .form-wrapper {
          position: unset;
        }
      }
    }

    .signup-link {
      //position: absolute;
      //bottom: 20px;
      //left: 0;
      //right: 0;

      a {
        &.left-arrow {
          &::before {
            font-family: FontAwesome;
            content: "\f100";
            margin-right: 5px;
          }
        }
        &.right-arrow {
          &::after {
            font-family: FontAwesome;
            content: "\f101";
            margin-left: 5px;
          }
        }
      }
    }
  }

  .logo {
    //background: #FF4F5A;
    padding: 10px;
    //width: 80px;
    //height: 80px;
    border-radius: 50%;

    img {
      margin-top: 5px;
    }
  }
}