/* Sidebar */

.sidebar {
  position: relative;
  display: block;
  height: 100%;
  min-height: 100vh;
  width: $sidebar-width;
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  background: $sidebar-bg;

  .sidebar-header {
    border-bottom: 1px solid #2c3744;
  }

  .navigation-menu {
    padding-left: 0;
    padding-bottom: 80px;
    margin-bottom: 0;
    margin-top: 18px;

    li {
      display: block;
      margin: 0;
      transition-duration: 0.25s;
      transition-timing-function: cubic-bezier(0.26, 0.66, 0.45, 0.78);
      transition-property: "background";

      &.nav-category-divider {
        position: sticky;
        top: 120px;
        display: block;
        background: $sidebar-bg;
        margin: 15px 0px 0px 0px;
        padding: 20px $sidebar-link-padding-right 10px $sidebar-link-padding-left;
        font-size: 10px;
        color: theme-color(primary);
        z-index: 1;
        @include font-face($TYPE-2, 500);

        &:first-child {
          margin-top: 0;
        }
      }

      a {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding: $sidebar-link-padding-top $sidebar-link-padding-right $sidebar-link-padding-bottom $sidebar-link-padding-left;
        font-size: $sidebar-link-font-size;
        line-height: $sidebar-link-line-height;
        color: $sidebar-link-color;
        letter-spacing: $letter-spacing;
        font-weight: 500;
        @include ellipsis;

        .link-icon {
          margin-right: 15px;
          line-height: $sidebar-link-line-height;
          color: $sidebar-link-color;
          @include font-size(19);
        }
      }

      &:last-child {
        a {
          border-bottom: none;
        }
      }

      .navigation-submenu {
        background: $sidebar-link-submenu-bg;
        padding: 0px 0 10px 30px;

        &.collapsing {
          transition: 0.2s ease-in;
        }

        li {
          display: inherit;

          a {
            display: block;
            padding: calc(#{$sidebar-link-padding-top - 3px}) $sidebar-link-padding-right calc(#{$sidebar-link-padding-bottom - 3px}) calc(#{$sidebar-link-padding-left} + 2px);
            opacity: 0.8;
            @include ms-respond(font-size, -2);
            letter-spacing: $letter-spacing;
            font-weight: 400;
            font-size: calc(#{$sidebar-link-font-size} - 1px);
            transition: 0.3s ease-in-out;
            transition-property: color;

            &[data-toggle="collapse"] {
              position: relative;

              &:after {
                content: "";
                height: 7px;
                width: 7px;
                border-radius: 25px;
                position: absolute;
                right: calc(#{$sidebar-link-padding-right} + 4px);
                top: 14px;
              }
            }

            &.active {
              color: theme-color(primary);
            }

            &:hover {
              color: theme-color(primary);
            }
          }

          &:first-child {
            a {
              padding-top: 10px;

              &:after {
                top: 15px;
              }
            }
          }

          &:nth-child(5n + 1) {
            a {
              &:after {
                background: theme-color(success);
              }
            }
          }

          &:nth-child(5n + 2) {
            a {
              &:after {
                background: theme-color(primary);
              }
            }
          }

          &:nth-child(5n + 3) {
            a {
              &:after {
                background: theme-color(danger);
              }
            }
          }

          &:nth-child(5n + 4) {
            a {
              &:after {
                background: theme-color(warning);
              }
            }
          }

          &:nth-child(5n + 5) {
            a {
              &:after {
                background: theme-color(info);
              }
            }
          }

          .navigation-submenu {
            padding-left: 0px;

            li {
              a {
                opacity: 0.5;
              }
            }
          }
        }
      }

      &.active {
        a {
          .link-title {
            color: $sidebar-active-link-color;
          }

          .link-icon {
            color: $sidebar-active-link-color;
          }
        }
      }
    }

    > li:not(.nav-category-divider) {

      &:hover {
        background: $sidebar-link-submenu-bg;
      }

      > a[data-toggle="collapse"] {
        position: relative;

        &:after {
          content: "\f142";
          font-family: "Material Design Icons";
          font-size: 15px;
          text-rendering: auto;
          line-height: inherit;
          font-weight: bolder;
          position: absolute;
          top: 13px;
          right: $sidebar-link-padding-right;
          display: block;
          transition: 0.3s;
          transition-property: transform;
          color: lighten($sidebar-link-color, 20%);
        }

        &[aria-expanded="true"] {
          background: $sidebar-link-submenu-bg;

          &:after {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .sidebar_footer {
    display: block;
    position: fixed;
    top: $header-height;
    left: 0;
    margin-bottom: auto;
    width: inherit;
    z-index: 2;
    background: $sidebar-bg;

    .admin-access-level {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      z-index: 9999;
      height: auto;
      width: 100%;
      background: $sidebar-bg;
      cursor: pointer;
      user-select: none;
      padding: 10px 20px;
      //box-shadow: -7px -2px 25px -5px rgba(0, 0, 0, 0.12);

      .user-type-wrapper {
        padding: 0px 0px 0px 10px;

        .user_name {
          color: $sidebar-link-color;
          margin-bottom: 8px;
          line-height: 1;
          @include ellipsis;
          max-width: 120px;
          @include font-face($TYPE-1, 500);
          letter-spacing: $letter-spacing;
        }

        .user_access_level {
          color: theme-color(dark);
          line-height: 1;
          @include ellipsis;
          max-width: 120px;
        }

        .status-indicator {
          margin-right: 5px;
        }
      }

      .arrow {
        color: $sidebar-link-color;
        margin-left: auto;
        font-size: 20px;
        line-height: 1;
        transition: 0.3s;
      }
    }

    .user-account {
      display: block;
      width: 100%;
      border-radius: 0px;
      border: none;
      margin-top: -100vh;
      opacity: 0;
      transition: 0.5s;
      border-right: $border-width $border-style $sidebar-boder-seperator-color;
      box-shadow: -7px -2px 25px -5px rgba(0, 0, 0, 0.12);

      &:before {
        display: none;
      }

      .user-profile-item-tittle {
        background: transparent;
        padding: 10px 18px 20px 18px;
        z-index: 1;
        color: $text-muted;
        @include ms-respond(font-size, -2);
        @include font-face($TYPE-1, 500);
      }

      .user-profile-itemcategory {
        background: transparent;
        margin-top: 10px;
        border-top: 1px solid $border-color;
        padding-top: 10px;
        @include font-face($TYPE-1, 500);
        letter-spacing: $letter-spacing;
      }

      .user-profile-item {
        display: flex;
        align-items: center;
        background: inherit;
        padding: 10px 18px;
        transition: 0.3s;
        z-index: 1;
        background: transparent;
        color: $sidebar-link-color;
        font-size: $sidebar-link-font-size;
        @include font-face($TYPE-1, 500);
        letter-spacing: $letter-spacing;

        &:hover {
          background: darken($sidebar-bg, 4%);
        }

        i {
          margin-right: 10px;
          font-size: 18px;
          line-height: 1;
          color: inherit;
        }

        img {
          margin-right: 10px;
        }
      }

      .btn-logout {
        width: 91%;
        max-width: 91%;
        margin: 15px 10px 15px 10px;
        border-radius: 4px;
      }
    }

    &.opened {
      background: $sidebar-footer-bg;

      .admin-access-level {
        background: $sidebar-footer-bg;

        .arrow {
          transform: rotate(-90deg);
        }
      }

      .user-account {
        margin-top: 60px;
        opacity: 1;
        background: $sidebar-footer-bg;
      }
    }
  }

  .sidebar_footer_copy {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    margin-top: auto;
    width: inherit;
    z-index: 2;
    background: $sidebar-bg;
    align-items: center;
    text-align: center;
    padding: 10px;

    img {
      width: 150px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }

    .tag-version {
      display: block;
      width: 100%;
      font-size: 11px;
      margin-top: -5px;
      color: #365963;
    }
  }
}