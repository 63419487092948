.button-date-picker {
  background: darken($template-body-color, 4%);
  clear: both;
  width: 200px;
  justify-content: left;
  text-transform: uppercase;

  .icon {
    margin-right: 2px;
    margin-left: 2px;
    right: 0;
    position: absolute;
  }
}