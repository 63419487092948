.loader-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  display: none;

  .loader {
    position: fixed;
    top: calc(50% - 1.5em);
    left: calc(50% - 1.5em);
    width: 3em;
    height: 3em;
    border: 0.3em solid rgba(0, 0, 0, 0.2);
    border-left: 0.3em solid $danger;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    //transition: opacity 0.4s;
  }
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}