.t-header .t-header-brand-wrapper a .logo {
  width: 150px;
}

// styles for input groups of login, register, forms
.custom-input-group {

  .input-group-prepend {

    span {
      font-size: 22px;
      background-color: #ECECEC;
      color: #5f6466;
    }
  }

  .form-control {
    //height: calc(1.5em + 0.75rem + 2px); // initial
    height: calc(1.9em + 1.3rem + 2px);
    padding: 0.375rem 0.75rem;
    background-color: #f5f5f5;
    color: #5f6466;

    border: none;

    &::placeholder {
      color: inherit;
    }
  }

}

// custom color for inputs errors
.invalid-feedback {
  color: theme-color(primary);
}

// custom table white-space
.no-white-space {

  th,
  td {
    white-space: normal !important;
  }
}

// checkbox
.checkbox label {
  padding-left: 35px;
}

.checkbox label .input-frame:after {
  top: 50% !important;
  transform: translateY(-50%) !important;
  font-size: 21px;
}

.checkbox label .input-frame:before {
  width: 25px;
  height: 25px;
  border-color: $input-border-color;
  background-color: $input-bg !important;
}

// disabled/readonly inputs
.form-control:disabled,
.form-control[readonly] {
  background-color: darken(#f8f9fa, 10%);
  opacity: 1;
}