.tips-board-wrapper {
  display: flex;
  align-items: flex-start;

  .tips-category {
    //padding: 20px;
    //background: $card-bg;
    border-radius: $border-radius;

    .tips-category-head {
      display: flex;
      align-items: center;
      padding-bottom: 30px;

      .heading-title {
        margin-bottom: 0;
      }

      .badge {
        margin-left: 15px;
      }

      .action-dropdown {
        margin-left: auto;
      }
    }

    ul.board-task {
      padding-left: 0;
      min-height: 20px;

      .tips-item {
        background: #fff;
        padding: 15px;
        border-radius: 5px;
        display: grid;
        position: relative;
        margin-bottom: 20px;
        transform: rotate(0deg);
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        transition-property: box-shadow, background, transform;

        &.ui-sortable-handle {
          &.ui-sortable-placeholder {
            display: block;
            min-height: 100px;
            background: green;
          }
        }

        &.ui-sortable-helper {
          transform: rotate(-3deg);
          box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.2);
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 10px;
          left: 0;
          width: 3px;
          height: 30px;
          border-radius: 0px $border-radius $border-radius 0;
        }

        .tips-title {
          @include font-face($TYPE-2, 600);
          color: $body-text-color;
          grid-column-start: 1;
          grid-column-end: 5;
          grid-row-start: 1;
        }

        .tips-time {
          color: rgba($body-text-color, 0.7);
          display: flex;
          align-items: center;
          justify-content: flex-end;
          grid-column-start: 5;
          grid-row-start: 1;

          small {
            @include font-face($TYPE-1, 500);
          }

          i {
            margin-right: 5px;
          }
        }

        .tips-actions {
          grid-column-start: 1;
          grid-column-end: 6;
          grid-row-start: 2;
          margin-top: 15px;
        }

        .tips-details {
          grid-column-start: 1;
          grid-column-end: 6;
          grid-row-start: 3;
          margin-top: 15px;
          color: rgba($body-text-color, 0.7);
        }

        .collaborators {
          display: flex;
          justify-content: flex-end;
          grid-column-start: 5;
          grid-row-start: 3;
          margin-top: 20px;
          margin-right: 10px;

          img,
          .plus-text {
            border-color: $template-body-color;
          }

        }

        &::before {
          background: theme-color(primary);
        }

        //&:nth-child(5n + 1) {
        //  &::before {
        //    background: theme-color(primary);
        //  }
        //}
        //
        //&:nth-child(5n + 2) {
        //  &::before {
        //    background: theme-color(success);
        //  }
        //}
        //
        //&:nth-child(5n + 3) {
        //  &::before {
        //    background: theme-color(danger);
        //  }
        //}
        //
        //&:nth-child(5n + 4) {
        //  &::before {
        //    background: theme-color(info);
        //  }
        //}
        //
        //&:nth-child(5n + 5) {
        //  &::before {
        //    background: theme-color(danger);
        //  }
        //}
      }
    }
  }
}