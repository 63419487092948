.report-page {

}

.donut-counter-card {
  margin: 10px 0;

  .donut-counter-value {
    color: #fff;
    font-size: 30px;
    text-align: center;
    width: 120px;
    border-radius: 3px;
    //padding: 20px;
    display: inline-block;

    &:after {
      content: '%';
      padding-left: 10px;
      font-size: 16px;
    }

  }

  .donut-counter-int {
    color: #fff;
    font-size: 30px;
    text-align: center;
    width: 85px;
    border-radius: 3px;
    //padding: 20px;
    display: inline-block;
  }

  .donut-counter-desc {
    //color: #fff;
    //font-size: 24px;
    text-align: left;
    //margin: 10px 0;
    display: inline-block;
  }
}