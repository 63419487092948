//@import '~@mdi/font/scss/materialdesignicons';
@import "~react-datepicker/dist/react-datepicker.min.css";

// custom
.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
}

.input-datepicker-group .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%
}

#root-portal .react-datepicker-popper {
  z-index: 100;
}