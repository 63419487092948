.t-header-search-box {

  .spinner {
    border-width: 0.08em;
  }

  .results-container {
    position: absolute;
    display: block;
    width: 100%;
    top: 42px;
    color: #525c5d;
    background-color: rgba(250, 216, 219, 1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: #525c5d;
    font-size: 0.875rem;

    max-height: 165px;
    overflow-y: auto;

    .item {
      padding: 5px;
      display: block;
      width: 100%;
      color: inherit;
      transition: color 0.4s ease;

      &:first-child {
        padding-top: 12px;
      }

      &:last-child {
        padding-bottom: 12px;
      }
    }

    a.item {
      &:hover {
        color: theme-color(primary);
      }
    }
  }

  .input-group-append {
    .input-group-text {
      border: none;
      background: transparent;
      font-size: 1.5rem;
      padding-left: 15px;
      line-height: 23px;

      &:hover {
        cursor: pointer;
      }
    }
  }

}