/* change all .btn to .btn-sm size on xs */
@include media-breakpoint-between(xs, lg) {
  .btn-responsive {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $line-height-lg, $btn-border-radius-lg);
  }
}

$report-logo-height: 40px;
$report-logo-padding: 20px;
$report-steps-height: 40px;
$report-header-padding: 10px;

$report-header-height: $report-logo-height + $report-logo-padding * 2 + $report-steps-height + $report-header-padding * 2;

.report-form-page {

  padding-top: $report-header-height;

  .viewport-header {
    padding: $report-header-padding;
    top: 0;
    position: fixed;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;

    .header-logo-container {
      padding: $report-logo-padding;

      img {
        max-width: 90%;
        width: auto;
        height: $report-logo-height;
      }
    }
  }

  .content-viewport {
  }
}